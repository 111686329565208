import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { getProblem, deleteProblem, updateProblem } from '../services/db';
import '../styles/ProblemDetailPage.css';
import '../styles/Theme.css';

function ProblemDetailPage() {
  const [problem, setProblem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProblem, setEditedProblem] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProblem = async () => {
      const loadedProblem = await getProblem(parseInt(id));
      setProblem(loadedProblem);
      setEditedProblem(loadedProblem);
    };
    loadProblem();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('정말로 이 문제를 삭제하시겠습니까?')) {
      try {
        await deleteProblem(parseInt(id));
        alert('문제가 삭제되었습니다.');
        navigate('/');
      } catch (error) {
        console.error('문제 삭제 실패:', error);
        alert('문제 삭제에 실패했습니다.');
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await updateProblem(parseInt(id), editedProblem);
      setProblem(editedProblem);
      setIsEditing(false);
      alert('문제가 수정되었습니다.');
    } catch (error) {
      console.error('문제 수정 실패:', error);
      alert('문제 수정에 실패했습니다.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProblem(prev => ({ ...prev, [name]: value }));
  };

  const renderMathContent = (content) => {
    if (typeof content !== 'string') return content;

    content = content.replace(/\\begin\{align\*\}/g, '$$\\begin{aligned}')
                     .replace(/\\end\{align\*\}/g, '\\end{aligned}$$')
                     .replace(/&=/g, '=')
                     .replace(/\\\\/g, '\\\\')
                     .replace(/\\text\{(\w+)\}/g, '$1');

    const parts = content.split(/(\$\$[\s\S]*?\$\$|\$[\s\S]*?\$)/g);
    return parts.map((part, index) => {
      if (part.startsWith('$$') && part.endsWith('$$')) {
        return <BlockMath key={index}>{part.slice(2, -2)}</BlockMath>;
      } else if (part.startsWith('$') && part.endsWith('$')) {
        return <InlineMath key={index}>{part.slice(1, -1)}</InlineMath>;
      }
      return part;
    });
  };

  if (!problem) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="problem-detail-page">
      <div className="header">
        <button className="button back-button" onClick={() => navigate('/')}>
          &#8592; 뒤로
        </button>
        <div className="action-buttons">
          <button className="button edit-button" onClick={handleEdit}>수정</button>
          <button className="button save-button" onClick={handleSave}>저장</button>
          <button className="button delete-button" onClick={handleDelete} aria-label="문제 삭제">
            🗑️
          </button>
        </div>
      </div>
      <div className="card problem-info">
        {problem.imageData && (
          <img src={`data:image/jpeg;base64,${problem.imageData}`} alt="Problem" className="problem-image" />
        )}
        <h3>문제</h3>
        {isEditing ? (
          <textarea
            name="content"
            value={editedProblem.content}
            onChange={handleChange}
            className="edit-textarea"
          />
        ) : (
          <div className="math-content">{renderMathContent(problem.content)}</div>
        )}
        <h3>풀이</h3>
        {isEditing ? (
          <textarea
            name="solution"
            value={editedProblem.solution}
            onChange={handleChange}
            className="edit-textarea"
          />
        ) : (
          <div className="math-content">{renderMathContent(problem.solution)}</div>
        )}
        <p>
          <strong>과목:</strong> 
          {isEditing ? (
            <input
              type="text"
              name="subject"
              value={editedProblem.subject}
              onChange={handleChange}
              className="edit-input"
            />
          ) : (
            problem.subject
          )}
        </p>
        <p>
          <strong>단원:</strong> 
          {isEditing ? (
            <input
              type="text"
              name="chapter"
              value={editedProblem.chapter}
              onChange={handleChange}
              className="edit-input"
            />
          ) : (
            problem.chapter
          )}
        </p>
      </div>
    </div>
  );
}

export default ProblemDetailPage;