import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import SavedListPage from './pages/SavedListPage';
import ProblemDetailPage from './pages/ProblemDetailPage';
import './styles/Theme.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/saved" element={<SavedListPage />} />
        <Route path="/problem/:id" element={<ProblemDetailPage />} />
      </Routes>
    </Router>
  );
}

export default App;