import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import PhotoUpload from '../components/PhotoUpload';
import ProblemCard from '../components/ProblemCard';
import LoadingSpinner from '../components/LoadingSpinner';
import { analyzeImage } from '../services/api';
import { saveProblem, getAllProblems } from '../services/db';
import { optimizeImage } from '../utils/imageOptimization';
import { imageToBase64 } from '../utils/imageUtils';
import '../styles/MainPage.css';
import '../styles/Theme.css';

function MainPage() {
  const [image, setImage] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savedProblems, setSavedProblems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadSavedProblems();
  }, []);

  const loadSavedProblems = async () => {
    const problems = await getAllProblems();
    setSavedProblems(problems.sort((a, b) => b.timestamp - a.timestamp));
  };

  const resetState = useCallback(() => {
    setImage(null);
    setAnalysis(null);
    setLoading(false);
    setIsEditing(false);
  }, []);

  const handleImageUpload = useCallback(async (uploadedImage) => {
    resetState();
    setImage(uploadedImage);
    setLoading(true);
    try {
      const optimizedImage = await optimizeImage(uploadedImage);
      const base64Image = await imageToBase64(optimizedImage);
      const result = await analyzeImage(base64Image);
      setAnalysis(result);
    } catch (error) {
      console.error('이미지 분석 실패:', error);
      alert(`이미지 분석에 실패했습니다: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [resetState]);

  const handleSave = async () => {
    if (analysis && image) {
      try {
        const optimizedImage = await optimizeImage(image);
        const base64Image = await imageToBase64(optimizedImage);
        const problemToSave = {
          ...analysis,
          imageData: base64Image,
          timestamp: Date.now()
        };
        await saveProblem(problemToSave);
        alert('문제가 저장되었습니다.');
        loadSavedProblems();
        resetState();
      } catch (error) {
        console.error('문제 저장 실패:', error);
        alert('문제 저장에 실패했습니다.');
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnalysis(prev => ({ ...prev, [name]: value }));
  };

  const handleCardClick = (problemId) => {
    navigate(`/problem/${problemId}`);
  };

  const renderMathContent = (content) => {
    if (typeof content !== 'string') return content;

    content = content
      .replace(/\\begin\{align\*\}/g, '$$\\begin{aligned}')
      .replace(/\\end\{align\*\}/g, '\\end{aligned}$$')
      .replace(/&=/g, '=')
      .replace(/\\\\/g, '\\\\')
      .replace(/\\text\{(\w+)\}/g, '$1')
      .replace(/\\cdot/g, '·')
      .replace(/\^(\d+)/g, '^{$1}')
      .replace(/([^\\])\*/g, '$1·');

    const parts = content.split(/(\$\$[\s\S]*?\$\$|\$[\s\S]*?\$)/g);
    return parts.map((part, index) => {
      if (part.startsWith('$$') && part.endsWith('$$')) {
        return <BlockMath key={index}>{part.slice(2, -2)}</BlockMath>;
      } else if (part.startsWith('$') && part.endsWith('$')) {
        return <InlineMath key={index}>{part.slice(1, -1)}</InlineMath>;
      }
      return part.split('\n').map((line, i) => (
        <React.Fragment key={`${index}-${i}`}>
          {line}
          {i < part.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  return (
    <div className="main-page">
      <div className="header">
        <h1>AI 오답 노트</h1>
        <button className="list-icon-button" onClick={() => navigate('/saved')} aria-label="저장 목록">
          <span className="list-icon">&#9776;</span>
        </button>
      </div>
      <div className="content-container">
        <div className="upload-section">
          <PhotoUpload onCapture={handleImageUpload} />
        </div>
        {loading && (
          <div className="loading-container">
            <p className="loading-message">AI 분석 중</p>
            <LoadingSpinner />
          </div>
        )}
        {image && !loading && (
          <img src={URL.createObjectURL(image)} alt="Uploaded" className="uploaded-image" />
        )}
        {analysis && (
          <div className="card analysis-result">
            <h2>분석 결과</h2>
            <h3>문제</h3>
            {isEditing ? (
              <textarea
                name="content"
                value={analysis.content}
                onChange={handleChange}
                className="edit-textarea"
              />
            ) : (
              <div className="math-content">{renderMathContent(analysis.content)}</div>
            )}
            <h3>풀이</h3>
            {isEditing ? (
              <textarea
                name="solution"
                value={analysis.solution}
                onChange={handleChange}
                className="edit-textarea"
              />
            ) : (
              <div className="math-content">{renderMathContent(analysis.solution)}</div>
            )}
            <p>
              <strong>과목:</strong> 
              {isEditing ? (
                <input
                  type="text"
                  name="subject"
                  value={analysis.subject}
                  onChange={handleChange}
                  className="edit-input"
                />
              ) : (
                analysis.subject
              )}
            </p>
            <p>
              <strong>단원:</strong> 
              {isEditing ? (
                <input
                  type="text"
                  name="chapter"
                  value={analysis.chapter}
                  onChange={handleChange}
                  className="edit-input"
                />
              ) : (
                analysis.chapter
              )}
            </p>
            <div className="action-buttons">
              {isEditing ? (
                <button className="button" onClick={() => setIsEditing(false)}>수정 완료</button>
              ) : (
                <button className="button" onClick={handleEdit}>수정</button>
              )}
              <button className="button" onClick={handleSave}>저장</button>
              <button className="button" onClick={resetState}>삭제</button>
            </div>
          </div>
        )}
        <div className="saved-problems">
          <h2>저장된 문제</h2>
          {savedProblems.map(problem => (
            <ProblemCard 
              key={problem.id} 
              problem={problem} 
              onClick={() => handleCardClick(problem.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MainPage;