import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProblemCard from '../components/ProblemCard';
import { getAllProblems, deleteProblem } from '../services/db';
import '../styles/SavedListPage.css';

function SavedListPage() {
  const [problems, setProblems] = useState([]);
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadProblems();
  }, []);

  const loadProblems = async () => {
    const loadedProblems = await getAllProblems();
    setProblems(loadedProblems.sort((a, b) => b.timestamp - a.timestamp));
  };

  const handleCardClick = (problemId) => {
    if (isSelectionMode) {
      setSelectedProblems(prev => 
        prev.includes(problemId) ? prev.filter(id => id !== problemId) : [...prev, problemId]
      );
    } else {
      navigate(`/problem/${problemId}`);
    }
  };

  const handleSelectAll = () => {
    setSelectedProblems(problems.map(p => p.id));
  };

  const handleDeselectAll = () => {
    setSelectedProblems([]);
  };

  const handleDelete = async () => {
    if (window.confirm(`선택한 ${selectedProblems.length}개의 문제를 삭제하시겠습니까?`)) {
      try {
        await Promise.all(selectedProblems.map(id => deleteProblem(id)));
        await loadProblems();
        setSelectedProblems([]);
        alert('선택한 문제들이 삭제되었습니다.');
      } catch (error) {
        console.error('문제 삭제 실패:', error);
        alert('문제 삭제에 실패했습니다.');
      }
    }
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    setSelectedProblems([]);
  };

  return (
    <div className="saved-list-page">
      <div className="header">
        <h1>저장된 문제 목록</h1>
        {isSelectionMode ? (
          <div className="selection-actions">
            <button onClick={handleSelectAll}>모두 선택</button>
            <button onClick={handleDeselectAll}>선택 해제</button>
            <button onClick={handleDelete}>삭제</button>
            <button onClick={toggleSelectionMode}>편집 종료</button>
          </div>
        ) : (
          <div className="header-actions">
            <button className="edit-mode-button" onClick={toggleSelectionMode}>목록편집</button>
            <button className="nav-button" onClick={() => navigate('/')}>메인으로</button>
          </div>
        )}
      </div>
      <div className="problem-list">
        {problems.map(problem => (
          <ProblemCard
            key={problem.id}
            problem={problem}
            onClick={() => handleCardClick(problem.id)}
            isSelected={selectedProblems.includes(problem.id)}
            isSelectionMode={isSelectionMode}
          />
        ))}
      </div>
    </div>
  );
}

export default SavedListPage;