import React from 'react';
import '../styles/Card.css';

function ProblemCard({ problem, onClick, isSelected, isSelectionMode }) {
  return (
    <div className={`card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      {isSelectionMode && (
        <div className="checkbox">
          {isSelected ? '☑' : '☐'}
        </div>
      )}
      <div className="card-content">
        <h3>{problem.content.slice(0, 30)}...</h3>
        <p>과목: {problem.subject}</p>
        <p>단원: {problem.chapter}</p>
      </div>
    </div>
  );
}

export default ProblemCard;