import React from 'react';
import { optimizeImage } from '../utils/imageOptimization';
import '../styles/PhotoUpload.css';

function PhotoUpload({ onCapture }) {
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const optimizedImage = await optimizeImage(file);
      onCapture(optimizedImage);
    }
  };

  return (
    <div className="upload-buttons">
      <label htmlFor="gallery-upload" className="upload-button">
        갤러리에서 선택
        <input 
          id="gallery-upload" 
          type="file" 
          accept="image/*"
          onChange={handleFileChange} 
          style={{display: 'none'}} 
        />
      </label>
      <label htmlFor="camera-capture" className="upload-button">
        카메라로 촬영
        <input 
          id="camera-capture" 
          type="file" 
          accept="image/*" 
          capture="environment"
          onChange={handleFileChange} 
          style={{display: 'none'}} 
        />
      </label>
    </div>
  );
}

export default PhotoUpload;