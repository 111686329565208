import { openDB } from 'idb';

const dbPromise = openDB('ProblemSolverDB', 1, {
  upgrade(db) {
    db.createObjectStore('problems', { keyPath: 'id', autoIncrement: true });
  },
});

export async function saveProblem(problem) {
  const db = await dbPromise;
  return db.add('problems', problem);
}

export async function getAllProblems() {
  const db = await dbPromise;
  return db.getAll('problems');
}

export async function getProblem(id) {
  const db = await dbPromise;
  return db.get('problems', id);
}

export async function deleteProblem(id) {
  const db = await dbPromise;
  return db.delete('problems', id);
}

export async function updateProblem(id, updatedProblem) {
  const db = await dbPromise;
  return db.put('problems', { ...updatedProblem, id });
}